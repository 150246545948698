export default function SettingsCommandes() {
  const resolveCommandesStatusVariantAndIcon = (status) => {
    if (status === "pending")
      return {
        variant: "warning",
        icon: "PieChartIcon",
        text: "Attente paiement",
      };
    if (status === "processing")
      return {
        variant: "success",
        icon: "CheckCircleIcon",
        text: "En cours",
      };
    if (status === "on-hold")
      return {
        variant: "warning",
        icon: "PieChartIcon",
        text: "En attente",
      };
    if (status === "completed")
      return { variant: "info", icon: "ArrowDownCircleIcon", text: "Terminée" };
    if (status === "cancelled")
      return { variant: "primary", icon: "SaveIcon", text: "Annulée" };
    if (status === "refunded")
      return { variant: "secondary", icon: "SendIcon", text: "Remboursée" };
    if (status === "failed")
      return { variant: "danger", icon: "InfoIcon", text: "Échouée" };

    return { variant: "secondary", icon: "XIcon" };
  };
  const resolveTextEtatPaiement = (etat) => {
    if (etat === true) return "Non payé";
    if (etat === false) return "Payé";
    return "Non payé";
  };
  return {
    resolveCommandesStatusVariantAndIcon,
    resolveTextEtatPaiement,
  };
}

<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div>
        <div class="card mb-4">
          <div class="card-header"></div>
          <div class="card-body">
            <h4>Filtrage</h4>
            <div class="row" style="margin-top: 30px">
              <div class="col-12 col-lg-3">
                <h6>Recherche :</h6>
                <b-form-input placeholder="Commande" v-model="searchQuery" />
              </div>
              <div class="col-12 col-lg-3">
                <h6>Statut :</h6>

                <v-select
                  :options="status"
                  v-model="statusFiltre"
                  label="text"
                  input-id="value"
                  value="value"
                >
                </v-select>
              </div>

              <div class="col-12 col-lg-3" style="margin-top: 23px">
                <div class="row">
                  <button
                    class="col-6 col-lg-6 btn btn-primary ml-1"
                    @click="SearchQuery()"
                  >
                    Filtrer
                  </button>

                  <button
                    class="col-4 col-lg-4 btn btn-secondary ml-1"
                    @click="clearSearchQuery()"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b-table
          :items="listOrders"
          :fields="fields"
          striped
          hover
          responsive
          :busy="isBusy"
        >
          <template #head(status)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          </template>

          <template #cell(status)="data">
            <b-avatar
              :id="`invoice-row-${data.item.id}`"
              size="32"
              :variant="`light-${
                resolveCommandesStatusVariantAndIcon(data.item.status).variant
              }`"
            >
              <feather-icon
                :icon="
                  resolveCommandesStatusVariantAndIcon(data.item.status).icon
                "
              />
            </b-avatar>
            <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
              <p
                class="mb-0"
                v-text="
                  resolveCommandesStatusVariantAndIcon(data.item.status).text
                "
              ></p>
            </b-tooltip>
          </template>
          <template #cell(id)="data">
            <b-link class="font-weight-bold"> #{{ data.value }} </b-link>
          </template>
          <template #cell(billing)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.value.first_name)"
                  :variant="`light-info`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.value.first_name }} {{ data.value.last_name }}
              </span>
              <small class="text-muted">{{ data.value.email }}</small>
            </b-media>
          </template>
          <template #cell(total)="data">
            <span> {{ data.value }} </span>
            <span>
              {{ data.item.currency }}
            </span>
          </template>
          <template #cell(needs_payment)="data">
            <span
              v-text="resolveTextEtatPaiement(data.item.needs_payment)"
            ></span>
          </template>
          <template #cell(date_created)="data">
            <span> {{ data.value.slice(0, 10) }} </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`invoice-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="mx-1"
                @click="RedirectToEditCommande(data.item.id)"
              />
              <b-tooltip
                title="Modification"
                :target="`invoice-row-${data.item.id}-edit-icon`"
                placement="left"
              />
              <feather-icon
                :id="`invoice-row-${data.item.id}-eye-icon`"
                icon="EyeIcon"
                @click="RedirectToDetailFacture(data.item.id)"
              />
              <b-tooltip
                title="Visualiser la facture"
                :target="`invoice-row-${data.item.id}-eye-icon`"
                placement="left"
              />

              <feather-icon
                :id="`invoice-row-${data.item.id}-trash-icon`"
                icon="TrashIcon"
                class="mx-1"
                @click="showMsgBox(data.item.id)"
              />
              <b-tooltip
                title="Supprimer"
                :target="`invoice-row-${data.item.id}-trash-icon`"
                placement="left"
              />
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style="margin-left: 5px">Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination
          class="mt-3"
          align="center"
          v-model="currentPage"
          :total-rows="totalOrders"
          :per-page="perPage"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import axios from "axios";
import websiteURL from "@/websiteURL";
import vSelect from "vue-select";
import SettingsCommandes from "./SettingsCommandes";
import { avatarText } from "@core/utils/filter";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BSpinner,
    BOverlay,
  },
  setup() {
    const { resolveCommandesStatusVariantAndIcon, resolveTextEtatPaiement } =
      SettingsCommandes();

    return {
      resolveCommandesStatusVariantAndIcon,
      resolveTextEtatPaiement,
    };
  },
  data() {
    return {
      avatarText,
      fields: [
        {
          label: "#",
          key: "id",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Status",
          key: "status",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Client",
          key: "billing",
        },
        {
          label: "Etat de paiement",
          key: "needs_payment",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Montant total",
          key: "total",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Date",
          key: "date_created",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      listOrders: [],
      perPage: 5,
      currentPage: 1,
      totalOrders: 0,
      searchQuery: null,
      status: [
        { value: "pending", text: "Attente paiement" },
        { value: "processing", text: "En cours" },
        { value: "on-hold", text: "En attente" },
        { value: "completed", text: "Terminée" },
        { value: "cancelled", text: "Annulée" },
        { value: "refunded", text: "Remboursée" },
        { value: "failed", text: "Échouée" },
      ],
      statusFiltre: null,
      boxMsg: "",
      loading: false,
    };
  },
  methods: {
    async RedirectToEditCommande(item) {
      this.$router.push({
        name: "Edit Commande",
        params: { id: item },
      });
    },
    async RedirectToDetailFacture(item) {
      this.$router.push({
        name: "Detail Facture",
        params: { id: item },
      });
    },
    //Permet la récuperation des commandes
    async fetchOrders(searchQuery, statusFiltre) {
      (this.listOrders = []), (this.isBusy = true);
      const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/orders";
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;

      const params = {
        per_page: this.perPage,
        page: this.currentPage,
        _fields: "id,status,billing,date_created,total,currency, needs_payment",
        status:
          "processing, pending, on-hold, completed, cancelled, refunded,failed",
      };

      if (searchQuery) {
        params.search = searchQuery;
      }
      if (statusFiltre) {
        params.status = statusFiltre.value;
      }

      try {
        const response = await axios.get(apiUrl, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
          params,
        });
        this.listOrders = response.data;
        this.totalOrders = response.headers["x-wp-total"];

        this.isBusy = false;
      } catch (error) {
        alert(
          "Une erreur s'est produite, veuillez réessayer plus tard, merci:",
          error
        );
        this.listOrders = [];
      }
    },
    handlePageChange(event) {
      if (event === null) {
        console.log("No page found");
      } else {
        this.currentPage = event;
        this.fetchOrders(this.searchQuery, this.statusFiltre);
      }
    },
    //Permet de filtrer les commandes
    async SearchQuery() {
      this.currentPage = 1;
      this.fetchOrders(this.searchQuery, this.statusFiltre);
    },
    //Permet de réinitialiser le filtre
    async clearSearchQuery() {
      this.searchQuery = null;
      this.statusFiltre = null;
      this.currentPage = 1;
      this.fetchOrders(this.searchQuery, this.statusFiltre);
    },
    async deleteCommande(idCommande) {
      const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/orders/${idCommande}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          status: "checkout-draft",
        };

        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.makeToastMsg("info");
      } catch (error) {
        alert("Une erreur s'est produite lors de la suppression");
      }
    },
    showMsgBox(idCommande) {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer la commande ?", {
          title: "Confirmation",
          size: "md",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.deleteCommande(idCommande).then(() => {
                this.loading = false;
                this.handlePageChange();
              });
            }
          }
        });
    },
    makeToastMsg(variant = null) {
      this.$bvToast.toast("La commande a bien été supprimer", {
        title: `Commande ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
  async mounted() {
    await this.fetchOrders();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
